import * as React from 'react';
import { GetServerSideProps } from 'next';
import SafetyCenterPage from 'components/safety';
import { getGeoCountry } from 'lib/get-geo-country';
import { getLocale } from 'lib/localization';
import { handleGeoMock } from 'lib/handle-geo-mock';

type Props = {
    geoIpCountry?: string | null;
};

const Index: React.FC<Props> = function () {
    return <SafetyCenterPage />;
};

export default Index;

export const getServerSideProps: GetServerSideProps<Props> = async function ({
    res,
    req,
    query,
    params,
}) {
    let country = getGeoCountry(req);
    const countryMock = handleGeoMock(query, req, res);

    if (countryMock) {
        country = countryMock;
    }

    return {
        props: {
            lang: getLocale(req, params),
            geoIpCountry: country,
            query,
        },
    };
};
