import React from 'react';
import Header from 'components/header/header';
import Logo from 'components/logo/logo';
import Button from 'components/button/button';
import { Lexeme } from '@bumble/localization/components';
import Icon from 'components/icon/icon';
import Link from 'components/link/link';
import ButtonGroup from 'components/button-group/button-group';
import CtaAppButton from 'components/cta-app-button/cta-app-button';
import useMediaQuery from 'lib/use-media-query';

const APPSFLYER_LINKS = {
    CAMPAIGNS: {
        SAFETY_CENTER: {
            APP_STORE: 'https://bumble.onelink.me/3396940749?pid=campaign&c=safetycentermicrosite',
            GOOGLE_PLAY:
                'https://bumble.onelink.me/3396940749?pid=campaign&c=safetycentermicrosite',
        },
    },
};

const actionSignInUrl = 'https://bumble.com/get-started/';

const actionIosAppDownloadUrl =
    'https://app.appsflyer.com/id930441707?pid=blog_self&amp;c=blog_index';

const actionAndroidAppDownloadUrl =
    'https://app.appsflyer.com/com.bumble.app?pid=blog_self&amp;c=blog_index';

const PageHeader = () => {
    const isBreakpoint = useMediaQuery(900);

    return (
        <Header
            logo={<Logo />}
            logoUrl={'https://bumble.com/'}
            actionIosAppDownload={
                <Button
                    labelText={
                        <Lexeme path="bma_client@dialog.photo_verification.not_access.download_app" />
                    }
                    isCircle={true}
                    color={Button.color.White}
                    designType={Button.designType.FILLED}
                    href={actionIosAppDownloadUrl}
                    icon={<Icon size="md" name={Icon.Name.Apple} />}
                />
            }
            actionAndroidAppDownload={
                <Button
                    labelText={
                        <Lexeme path="bma_client@dialog.photo_verification.not_access.download_app" />
                    }
                    isCircle={true}
                    color={Button.color.White}
                    designType={Button.designType.FILLED}
                    href={actionAndroidAppDownloadUrl}
                    icon={<Icon size="md" name={Icon.Name.Android} />}
                />
            }
            actionSignIn={
                isBreakpoint ? null : (
                    <Link className="link link--white" href={actionSignInUrl}>
                        <Lexeme path="bma_client@pages.login-password.submit" />
                    </Link>
                )
            }
            actionDesktopButtons={
                <ButtonGroup size="lg">
                    <CtaAppButton
                        market={CtaAppButton.market.APP_STORE}
                        href={APPSFLYER_LINKS.CAMPAIGNS.SAFETY_CENTER.APP_STORE}
                        height={38}
                    />
                    <CtaAppButton
                        market={CtaAppButton.market.GOOGLE_PLAY}
                        href={APPSFLYER_LINKS.CAMPAIGNS.SAFETY_CENTER.GOOGLE_PLAY}
                        height={38}
                    />
                </ButtonGroup>
            }
        />
    );
};

export default PageHeader;
