import React from 'react';
import LocaleContext from 'lib/locale-context';
import { Lexeme } from '@bumble/localization/components';
import CtaBox from 'components/cta-box/cta-box';
import CtaBoxTitle from 'components/cta-box/cta-box-title';
import CtaBoxMedia from 'components/cta-box/cta-box-media';
import Image from 'next/legacy/image';
import Header3 from 'components/typography/header-3';
import P2 from 'components/typography/p-2';

const CARDS_TITLE_TEXT_GAP_COMPENSATION = -22;
const CARD_IMAGE_HEIGHT = 205;

const SafetyFeaturesCards: React.FC<React.PropsWithChildren> = () => {
    const lang = React.useContext(LocaleContext);

    const safetyFeatureCardsList = [
        {
            key: 'bumblesite@SAFETY_CENTER.SAFETY_FEATURES.CARDS.ONE.TITLE',
            // imageUrl: '/howtos/where-to-start.png',
            imageUrl: `/l_image/${lang}/Photo-verification.png`,
            title: <Lexeme path="bumblesite@SAFETY_CENTER.SAFETY_FEATURES.CARDS.ONE.TITLE" />,
            text: <Lexeme path="bumblesite@SAFETY_CENTER.SAFETY_FEATURES.CARDS.ONE.TEXT" />,
        },
        {
            key: 'bumblesite@SAFETY_CENTER.SAFETY_FEATURES.CARDS.TWO.TITLE',
            imageUrl: `/l_image/${lang}/Private-detector.png`,
            title: <Lexeme path="bumblesite@SAFETY_CENTER.SAFETY_FEATURES.CARDS.TWO.TITLE" />,
            text: <Lexeme path="bumblesite@SAFETY_CENTER.SAFETY_FEATURES.CARDS.TWO.TEXT" />,
        },
        {
            key: 'bumblesite@SAFETY_CENTER.SAFETY_FEATURES.CARDS.THREE.TITLE',
            imageUrl: `/l_image/${lang}/Block.png`,
            title: <Lexeme path="bumblesite@SAFETY_CENTER.SAFETY_FEATURES.CARDS.THREE.TITLE" />,
            text: <Lexeme path="bumblesite@SAFETY_CENTER.SAFETY_FEATURES.CARDS.THREE.TEXT" />,
        },
        {
            key: 'bumblesite@SAFETY_CENTER.SAFETY_FEATURES.CARDS.FOUR.TITLE',
            imageUrl: `/l_image/${lang}/Snooze.png`,
            title: <Lexeme path="bumblesite@SAFETY_CENTER.SAFETY_FEATURES.CARDS.FOUR.TITLE" />,
            text: <Lexeme path="bumblesite@SAFETY_CENTER.SAFETY_FEATURES.CARDS.FOUR.TEXT" />,
        },
    ];

    const safetyFeatureCards = (
        <ul className="cards cards--features">
            {safetyFeatureCardsList.map(({ key, imageUrl, title, text }) => {
                return (
                    <li className="cards__item" key={key}>
                        <CtaBox isFull={true}>
                            {imageUrl ? (
                                <CtaBoxMedia>
                                    {/* {category} */}
                                    <Image
                                        className="cta-box__media-img"
                                        src={imageUrl}
                                        width={280}
                                        height={CARD_IMAGE_HEIGHT}
                                        layout="responsive"
                                        objectFit="contain"
                                        alt=""
                                    />
                                </CtaBoxMedia>
                            ) : (
                                <div style={{ height: CARD_IMAGE_HEIGHT }} />
                            )}
                            {title ? (
                                <CtaBoxTitle marginTop={45}>
                                    <span className="cards__title">
                                        <Header3>{title}</Header3>
                                    </span>
                                </CtaBoxTitle>
                            ) : (
                                ''
                            )}
                            {text ? (
                                <CtaBoxTitle marginTop={CARDS_TITLE_TEXT_GAP_COMPENSATION}>
                                    <span className="cards__text">
                                        <P2>{text}</P2>
                                    </span>
                                </CtaBoxTitle>
                            ) : (
                                ''
                            )}
                        </CtaBox>
                    </li>
                );
            })}
        </ul>
    );

    return safetyFeatureCards;
};

export default SafetyFeaturesCards;
