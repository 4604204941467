import React from 'react';
import Button from 'components/button/button';
import ButtonGroup from 'components/button-group/button-group';
import { howToGuidesCategoryNames } from './how-to.config';

type HowToGuideButtonsPropsType = {
    filter?: React.ReactNode;
    onClickHandler?: (value?: React.ReactNode) => void;
};

const HowToGuideButtons: React.FC<HowToGuideButtonsPropsType> = (props) => {
    const { filter, onClickHandler } = props;

    const buttons = howToGuidesCategoryNames.map((item) => {
        const key = item[0];
        const value = item[1];
        return (
            <Button
                key={key}
                text={value}
                onClick={
                    onClickHandler
                        ? () => {
                              onClickHandler(key);
                          }
                        : undefined
                }
                extraClass={`button--filter ${key === filter ? 'is-active' : ''}`}
            />
        );
    });
    return (
        <ButtonGroup size="sm" extraClass="button-group--scroll">
            {buttons}
        </ButtonGroup>
    );
};

export default HowToGuideButtons;
