import React from 'react';
import { NextSeo } from 'next-seo';
import Localization from 'lib/localization';

const SEO = {
    get TAB_TITLE() {
        return Localization.get('bumblesite@SAFETY_CENTER.BROWSER_TAB');
    },
    get NAME() {
        return Localization.get('bumblesite@SAFETY_CENTER.SOCIAL_SHARING.TITLE');
    },
    get TITLE() {
        return Localization.get('bumblesite@SAFETY_CENTER.SOCIAL_SHARING.TITLE');
    },
    get DESCRIPTION() {
        return Localization.get('bumblesite@SAFETY_CENTER.SOCIAL_SHARING.DESCRIPTION');
    },
    URL: 'https://safety.bumble.com',
    IMAGE_URL: global.window
        ? `${global.window.location.origin}/social-sharing.jpg`
        : 'https://safety.bumble.com/social-sharing.jpg',
    get IMAGE_ALT() {
        return Localization.get('bumblesite@SAFETY_CENTER.BROWSER_TAB');
    },
};

const PageCookie = () => {
    return (
        <NextSeo
            title={SEO.TAB_TITLE}
            description={SEO.DESCRIPTION}
            canonical={SEO.URL}
            openGraph={{
                url: SEO.URL,
                title: SEO.TITLE,
                description: SEO.DESCRIPTION,
                images: [
                    {
                        url: SEO.IMAGE_URL,
                        width: 1200,
                        height: 630,
                        alt: SEO.IMAGE_ALT,
                    },
                ],
                site_name: SEO.NAME,
            }}
            twitter={{
                handle: '@handle',
                site: '@site',
                cardType: 'summary_large_image',
            }}
        />
    );
};

export default PageCookie;
