import React from 'react';
import { Lexeme } from '@bumble/localization/components';
import HeroBlock from 'components/hero-block/hero-block';
import CtaBox, { CtaBoxAlign } from 'components/cta-box/cta-box';
import CtaBoxTitle, { CtaBoxTitleAlign } from 'components/cta-box/cta-box-title';
import CtaBoxMedia, { CtaBoxMediaAlign } from 'components/cta-box/cta-box-media';
import Header1 from 'components/typography/header-1';
import Image from 'next/legacy/image';
import { Assets } from 'components/assets/assets';
import useMediaQuery from 'lib/use-media-query';

const PageHeroBlock = () => {
    const isBreakpoint = useMediaQuery(900);
    return (
        <HeroBlock color="primary">
            <CtaBox align={isBreakpoint ? undefined : CtaBoxAlign.CENTERED}>
                <CtaBoxTitle align={CtaBoxTitleAlign.CENTERED}>
                    <Header1 color="white" style={{ maxWidth: 680, margin: '0 auto' }}>
                        <Lexeme path="bumblesite@LAYOUT.HERO_BLOCK.TITLE" />
                    </Header1>
                </CtaBoxTitle>
                <CtaBoxMedia align={CtaBoxMediaAlign.CENTERED} marginTop={isBreakpoint ? 32 : 54}>
                    {isBreakpoint ? (
                        <Image
                            className="cta-box__media-img"
                            src={Assets.SAFETY_CENTER_HERO_BLOCK_MOBILE}
                            width={global.screen.width}
                            height={global.screen.width * 0.9086}
                            objectFit="contain"
                            priority
                            alt=""
                        />
                    ) : (
                        <Image
                            className="cta-box__media-img"
                            src={Assets.SAFETY_CENTER_HERO_BLOCK_DESKTOP}
                            width={1224}
                            height={500}
                            objectFit="contain"
                            priority
                            alt=""
                        />
                    )}
                </CtaBoxMedia>
            </CtaBox>
        </HeroBlock>
    );
};

export default PageHeroBlock;
