import React from 'react';
import Text, { TextType, TextProps } from 'components/typography/text';

const Header1: React.FC<React.PropsWithChildren<TextProps>> = (props) => {
    return (
        <Text type={TextType.H1} {...props}>
            {props.children}
        </Text>
    );
};

export default Header1;
