import React from 'react';
import cx from 'classnames';
import supportedLanguageIds, { SupportedLanguage } from 'lib/lexemes/supported-languages';

type Props = {
    currentLanguageCode?: string;
    currentLanguageName?: string;
};

const SUPPORTED_LANGUAGE_LIST: Array<{ code: SupportedLanguage; name: string }> = [
    {
        code: 'en',
        name: 'English (United Kingdom)',
    },
    {
        code: 'en-us',
        name: 'English (United States)',
    },
    {
        code: 'en-in',
        name: 'English (India)',
    },
    {
        code: 'hi',
        name: 'हिंदी',
    },
    {
        code: 'fr',
        name: 'Français',
    },
    {
        code: 'fr-ca',
        name: 'Français (Canada)',
    },
    {
        code: 'pt',
        name: 'Português(Brasil)',
    },
    {
        code: 'pt-pt',
        name: 'Português(Portugal)',
    },
    {
        code: 'nl',
        name: 'Nederlands',
    },
    {
        code: 'it',
        name: 'Italiano',
    },
    {
        code: 'de',
        name: 'Deutsch',
    },
    {
        code: 'es',
        name: 'Español (España)',
    },
    {
        code: 'es-mx',
        name: 'Español (México)',
    },
    {
        code: 'ru',
        name: 'Русский',
    },
    {
        code: 'sv',
        name: 'Svenska',
    },
    {
        code: 'nb',
        name: 'Norsk bokmål',
    },
    {
        code: 'da',
        name: 'Dansk',
    },
    {
        code: 'ja',
        name: '日本語',
    },
    {
        code: 'ko',
        name: '한국어',
    },
    {
        code: 'fi',
        name: 'Suomi',
    },
    {
        code: 'zh',
        name: '中文 (简体)',
    },
    {
        code: 'zh-Hant',
        name: '中文 (繁體)',
    },
    {
        code: 'pl',
        name: 'Polski',
    },
];

function setLocalisedUrl(lang: string) {
    // next.js server issue
    // const origin = global.window ? global.window.location.origin : '';
    const origin = '';
    // const queryParams = global.window ? global.window.location.search : '';
    // const hash = global.window ? global.window.location.hash : '';
    // return lang + queryParams + hash;
    // TODO: temporary for tests
    return `${origin}/${lang}`;
}

function LanguageSelector(props: Props) {
    const { currentLanguageCode } = props;
    const currentLanguageName = SUPPORTED_LANGUAGE_LIST.find(
        ({ code }) => code === currentLanguageCode
    )?.name;

    return (
        <div className="language-selector">
            <div className="language-selector__inner">
                <label className="language-selector__label" htmlFor="language-selector-toggle">
                    {currentLanguageName}
                </label>
                <input
                    className="language-selector__trigger"
                    type="checkbox"
                    id="language-selector-toggle"
                />
                <div className="language-selector__dropdown">
                    <div className="dropdown-list dropdown-list--top">
                        <div className="dropdown-list__inner">
                            {SUPPORTED_LANGUAGE_LIST.filter(({ code }) =>
                                supportedLanguageIds.includes(code)
                            ).map(({ code, name }) => {
                                const isActive = currentLanguageCode === code;
                                const className = cx({
                                    'dropdown-list__item': true,
                                    'is-active': isActive,
                                });

                                if (isActive) {
                                    return (
                                        <span className={className} key={`active-${code}-${name}`}>
                                            <span className="dropdown-list__item-title">
                                                {name}
                                            </span>
                                        </span>
                                    );
                                }
                                return (
                                    <a
                                        className={className}
                                        href={code ? setLocalisedUrl(code) : code}
                                        key={`${code}-${name}`}
                                    >
                                        <span className="dropdown-list__item-title">{name}</span>
                                    </a>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LanguageSelector;
