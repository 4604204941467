import React from 'react';
import Text, { TextType, TextProps } from 'components/typography/text';

const Header3: React.FC<React.PropsWithChildren<TextProps>> = (props) => (
    <Text type={TextType.H3} {...props}>
        {props.children}
    </Text>
);

export default Header3;
