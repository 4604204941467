import React from 'react';
import YouTube, { YouTubeProps } from 'react-youtube';
import Header2 from 'components/typography/header-2';
import MediaBox, { MediaBoxAspect, MediaBoxType } from 'components/media-box/media-box';
import { Lexeme } from '@bumble/localization/components';

const VIDEO_ID = 'D-8-rPojyCA';
const opts = {
    playerVars: {
        autoplay: 1,
        color: 'white',
        modestbranding: 1,
        rel: 0,
    },
};
const campaignName = 'Bumble Safety Center video from ';

const PageVideoBlock = () => {
    const [isOpened, setOpened] = React.useState(false);
    const playBackHandler: YouTubeProps['onPlay'] = () => {
        setOpened(!isOpened);
    };

    React.useEffect(() => {
        /**
         * solving bag with fullscreen opens and closes window
         */
        const ua = global.navigator.userAgent;

        if (
            !!ua.match(/(iPad).*OS\s([\d_]+)/) ||
            !!ua.match(/(iPhone\sOS)\s([\d_]+)/) ||
            !!ua.match(/(Android)\s+([\d.]+)/) ||
            !!ua.match(/Silk-Accelerated/)
        ) {
            setOpened(true);
        }
    }, [setOpened]);

    return isOpened ? (
        <div
            style={{
                width: '100%',
                position: 'relative',
            }}
        >
            <div
                style={{
                    width: '100%',
                    paddingTop: '56.35%',
                }}
            ></div>
            <YouTube
                videoId={VIDEO_ID}
                containerClassName="media-box-youtube"
                className="media-box-youtube__frame"
                opts={opts as YouTubeProps['opts']}
                onEnd={playBackHandler}
                onReady={() => {
                    if (global.window.ga) {
                        global.window.ga('set', 'location', global.window.location.href);
                        global.window.ga(
                            'send',
                            'event',
                            campaignName + global.window.location.href,
                            'load',
                            campaignName + global.window.location.href,
                            {
                                nonInteraction: true,
                            }
                        );
                    }
                }}
                onStateChange={(event) => {
                    const playerState = Object.entries(YouTube.PlayerState).find(
                        (item) => item[1] === event.target.getPlayerState()
                    );
                    if (global.window.ga) {
                        global.window.ga(
                            'send',
                            'event',
                            campaignName + global.window.location.href,
                            playerState && playerState[0] ? playerState[0] : 'undefined',
                            campaignName + global.window.location.href
                        );
                    }
                }}
            />
        </div>
    ) : (
        <MediaBox
            type={MediaBoxType.VIDEO}
            aspect={MediaBoxAspect.WIDE_LANDSCAPE}
            src={'video-bg.png'}
            hasVideoOpenAnimation={true}
            onExternalPlayClick={playBackHandler}
            titleElement={
                <Header2>
                    <Lexeme path="bumblesite@SAFETY_CENTER.VIDEO.TITLE" />
                </Header2>
            }
        />
    );
};

export default PageVideoBlock;
