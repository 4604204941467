import React, { useEffect, useState, useCallback, useContext } from 'react';
import cx from 'classnames';
import Icon from 'components/icon/icon';
import Link from 'components/link/link';
import Button from 'components/button/button';
import { Lexeme } from '@bumble/localization/components';
import LocaleContext from 'lib/locale-context';
import { getCookieSettings } from 'lib/has-cookie-consent';
import { useRouter } from 'next/router';

type Props = {
    ctaLink?: string;
    icon?: React.ReactNode;
    hasBlurredBg?: boolean;
    hasCta?: boolean;
    title?: string | React.ReactNode;
    text?: string;
};

function CookieNotification(props: Props) {
    const lang = useContext(LocaleContext);

    const {
        ctaLink = `https://bumble.com/${lang}/cookie-policy`,
        icon = <Icon size="md" name={Icon.Name.Close} />,
        hasBlurredBg,
        hasCta,
        title,
        text,
    } = props;

    const className = {
        block: cx({
            'cookie-notification': true,
            'cookie-notification--blurred-bg': hasBlurredBg,
            'cookie-notification--cta': hasCta,
        }),
        closeAction: cx({
            'cookie-notification__close': true,
        }),
    };

    const [isVisible, setIsVisible] = useState(false);
    const router = useRouter();

    useEffect(() => {
        const showCookieBanner = () => {
            setIsVisible(true);
            global.document.body.classList.add('show-cookie-notification');
        };

        if (
            (router && router.pathname.includes('the-buzz')) ||
            (router && router.route.includes('the-buzz'))
        ) {
            if (!cookie('cookie_banner_closed')) {
                showCookieBanner();
            }
        } else {
            if (!getCookieSettings()) {
                showCookieBanner();
            }
        }
    }, [router]);

    const hideCookieBanner = useCallback(() => {
        setIsVisible(false);
        cookie('cookie_banner_closed', true, '', '/');
        global.document.body.classList.remove('show-cookie-notification');
    }, []);

    const hideCookieBannerWithAccept = useCallback(() => {
        setIsVisible(false);
        acceptAllCookies();
        global.document.body.classList.remove('show-cookie-notification');
    }, []);

    return (
        <div className={className.block} style={{ display: isVisible ? 'block' : 'none' }}>
            <div className="cookie-notification__inner">
                {title ? <div className="cookie-notification__title">{title}</div> : null}

                <div
                    className="cookie-notification__text"
                    dangerouslySetInnerHTML={
                        text
                            ? {
                                  __html: text,
                              }
                            : undefined
                    }
                >
                    {text ? null : (
                        <>
                            <Lexeme path="bma_client@cookie-notification.text" />{' '}
                            <Link
                                className="cookie-notification__link"
                                href={ctaLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Lexeme path="settings@cookies.footer.cookie_policy" />
                            </Link>
                        </>
                    )}
                </div>

                {hasCta ? (
                    <div className="cookie-notification__actions">
                        <div className="cookie-notification__action">
                            <Button
                                size={Button.size.SMALL}
                                target="_blank"
                                href="https://bumble.com/cookie-policy#functional"
                                text={<Lexeme path="settings@cookies.banner.settings_cta" />}
                            />
                        </div>

                        <div className="cookie-notification__action">
                            <Button
                                size={Button.size.SMALL}
                                designType={Button.designType.FILLED}
                                color={Button.color.White}
                                jsClass="js-cookie-accept-all"
                                text={<Lexeme path="settings@cookies.banner.accept_cta" />}
                                onClick={hideCookieBannerWithAccept}
                            />
                        </div>
                    </div>
                ) : null}

                {!hasCta ? (
                    <div className={className.closeAction} onClick={hideCookieBanner}>
                        <div className="cookie-notification__icon">{icon}</div>
                    </div>
                ) : null}
            </div>
        </div>
    );
}

export default CookieNotification;

// images/-/moxie/new/js/components/cookie-banner.js
// eslint-disable-next-line max-params
function cookie(
    name: string,
    value?: any,
    expires?: Date | string,
    path?: string,
    domain?: string,
    secure?: any
): string | boolean | undefined {
    if (arguments.length === 1) {
        return global.document.cookie.match(new RegExp(`(^|; )${name}=(.*?)(;|$)`))
            ? decodeURIComponent(RegExp.$2)
            : undefined;
    }

    global.document.cookie = `${name}=${encodeURIComponent(value)}${
        expires ? `; expires=${expires instanceof Date ? expires.toUTCString() : expires}` : ''
    }${path ? `; path=${path}` : ''}${domain ? `; domain=${domain}` : ''}${
        secure ? '; secure' : ''
    }`;

    return cookie(name) === value;
}

// new cookie consenst
function acceptAllCookies() {
    const cookies = { functional: true, analytics: true };
    const cookieSettings = encodeURIComponent(JSON.stringify(cookies));
    const expiryDate = new Date();
    expiryDate.setTime(Number(new Date()) + 1000 * 60 * 60 * 24 * 365);
    global.document.cookie = `cookie_settings=${cookieSettings}; expires=${expiryDate.toUTCString()}`;
}
