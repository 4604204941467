import React from 'react';
import Icon from 'components/icon/icon';
import Link from 'components/link/link';

enum CtaAppButtonMarket {
    APP_STORE = 'APP_STORE',
    GOOGLE_PLAY = 'GOOGLE_PLAY',
}

const mapMarketToIcon: { [key in CtaAppButtonMarket]: string } = {
    [CtaAppButtonMarket.APP_STORE]: Icon.Name.AppStoreButton,
    [CtaAppButtonMarket.GOOGLE_PLAY]: Icon.Name.GooglePlayButton,
};

type CtaAppButtonType = {
    href?: string;
    market: CtaAppButtonMarket;
    height?: number;
};

function CtaAppButton({ href, market, height }: CtaAppButtonType) {
    let ctaWidth: number | string = market === CtaAppButtonMarket.APP_STORE ? 120 : 135;
    const ctaHeight = height || 40;
    if (height) {
        ctaWidth = market === CtaAppButtonMarket.APP_STORE ? height * 3 : height * 3.375;
    }

    return (
        <Link href={href} className="cta-app-button-wrapper">
            <span
                className="cta-app-button"
                style={{
                    width: ctaWidth,
                    height: ctaHeight,
                }}
            >
                <Icon size="stretch" name={mapMarketToIcon[market]} />
            </span>
        </Link>
    );
}

export default CtaAppButton;

CtaAppButton.market = CtaAppButtonMarket;
