import React from 'react';

type Props = {
    children?: React.ReactNode;
};

function FooterCaption(props: Props) {
    return (
        <div className="footer__caption">
            <div className="footer__caption-language trigger-dropdown-list">{props.children}</div>
        </div>
    );
}

export default FooterCaption;
