import React from 'react';

type Props = {
    children?: React.ReactNode[];
    copyright?: string | React.ReactNode;
};

function Footer(props: Props) {
    const { children, copyright } = props;

    return (
        <div className="footer">
            <div className="footer__inner">
                {React.Children.map(children, (child) => (
                    <div className="footer__inner-item">{child}</div>
                ))}
            </div>

            {copyright ? <div className="footer__copyright">{copyright}</div> : null}
        </div>
    );
}

export default Footer;
