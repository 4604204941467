import React from 'react';
import { Lexeme } from '@bumble/localization/components';

// controls order
export const howToGuidesCategories = {
    // Bumble’s Safety Handbook ?
    // safetyGuide: 'Bumble’s Safety Guide and Features',
    consent: 'Consent', // @TODO - add lexeme
    harmfulBehaviour: <Lexeme path="profile@safety_center.cards.harmful_behaviour.title" />,
    bumbleToIrl: <Lexeme path="profile@safety_center.cards.bumble_to_irl.title" />,
    romanceScams: 'Romance scams and cyber stalking', // - wait for artwork
    mentalExhaustion: <Lexeme path="profile@safety_center.cards.mental_exhaustion.title" />,
    feelingsOfRejection: <Lexeme path="profile@safety_center.cards.feelings_of_rejection.title" />,
};
export const howToGuidesCategoryNames = Object.entries(howToGuidesCategories);

export const howToGuidesCategoriesIds = {
    // safetyGuide: 'safetyGuide',
    consent: 'consent',
    harmfulBehaviour: 'harmfulBehaviour',
    bumbleToIrl: 'bumbleToIrl',
    romanceScams: 'romanceScams',
    mentalExhaustion: 'mentalExhaustion',
    feelingsOfRejection: 'feelingsOfRejection',
};
