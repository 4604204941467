import React from 'react';
import classNames from 'classnames';

export enum TextType {
    TITLE = 'title',
    CAPTION = 'caption',
    H1 = 'h1',
    H2 = 'h2',
    H3 = 'h3',
    H4 = 'h4',
    P1 = 'p-1',
    P2 = 'p-2',
    P3 = 'p-3',
}

export type TextProps = {
    isInline?: boolean;
    type?: TextType;
    isMedium?: boolean;
    hasNoWrap?: boolean;
    isExtended?: boolean;
    isInherited?: boolean;
    extraClass?: string;
    color?: 'white';
    style?: any;
};

const Text: React.FC<React.PropsWithChildren<TextProps>> = (props) => {
    const {
        isInline,
        type,
        color,
        isMedium,
        hasNoWrap,
        isExtended,
        isInherited,
        extraClass,
        style,
    } = props;
    const classnames = {
        block: classNames(
            typeof type !== 'undefined' && type,
            {
                'is-medium': isMedium,
                'has-no-wrap': hasNoWrap,
                'is-extended': isExtended,
                'is-inherited': isInherited,
                [extraClass as string]: extraClass,
            },
            color && typeof color === 'string' ? color : null
        ),
    };
    const TagName = isInline ? 'span' : 'div';
    return (
        <TagName className={classnames.block} style={style}>
            {props.children}
        </TagName>
    );
};

export default Text;
