/**
 * all assets are stored in frontend-client/public/*
 */
const SafetyCenterHeroBlockDesktop = '/safety-center-hero-block-desktop.png';
const SafetyCenterHeroBlockMobile = '/safety-center-hero-block-mobile.png';

enum PlaceholderType {
    SAFETY_CENTER_HERO_BLOCK_DESKTOP = 'SAFETY_CENTER_HERO_BLOCK_DESKTOP',
    SAFETY_CENTER_HERO_BLOCK_MOBILE = 'SAFETY_CENTER_HERO_BLOCK_MOBILE',
}

const mapPlaceholderTypeToImage: { [key in PlaceholderType]: string } = {
    [PlaceholderType.SAFETY_CENTER_HERO_BLOCK_DESKTOP]: SafetyCenterHeroBlockDesktop,
    [PlaceholderType.SAFETY_CENTER_HERO_BLOCK_MOBILE]: SafetyCenterHeroBlockMobile,
};

export const Assets = {
    SAFETY_CENTER_HERO_BLOCK_DESKTOP:
        mapPlaceholderTypeToImage[PlaceholderType.SAFETY_CENTER_HERO_BLOCK_DESKTOP],
    SAFETY_CENTER_HERO_BLOCK_MOBILE:
        mapPlaceholderTypeToImage[PlaceholderType.SAFETY_CENTER_HERO_BLOCK_MOBILE],
};
