import React from 'react';
import cx from 'classnames';

type ColumnsPropsType = {
    children?: React.ReactNode | React.ReactNode[];
    width?: number | string;
    horizontalPadding?: number;
    isMobileOrderInverted?: boolean;
};

function Columns(props: ColumnsPropsType) {
    const className = cx({
        columns: true,
        'columns--mobile-inverted': props.isMobileOrderInverted,
    });

    return (
        <div
            className={className}
            style={{
                width: props.width,
                padding: props.horizontalPadding ? `0px ${props.horizontalPadding}px` : undefined,
            }}
        >
            {props.children}
        </div>
    );
}

export default Columns;
