import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import Page from 'components/page/page';
import Header2 from 'components/typography/header-2';
import P1 from 'components/typography/p-1';
import { Columns, ColumnsItem } from 'components/columns';
import PageSection from 'components/page/page-section';
import Modal from 'components/modal/modal';
import { Lexeme } from '@bumble/localization/components';
import CtaBox from 'components/cta-box/cta-box';
import CtaBoxTitle from 'components/cta-box/cta-box-title';
import CtaBoxMedia from 'components/cta-box/cta-box-media';

import useMediaQuery from 'lib/use-media-query';
import { onUseCookieConsentEvent, onUsePrivacyManagerHandler } from 'lib/cookie-consent';
import Icon, { IconDefs } from 'components/icon/icon';

import PageSeo from './components/page-seo/page-seo';
import PageHeader from './components/page-header/page-header';
import PageHeroBlock from './components/page-hero-block/page-hero-block';
import PageVideoBlock from './components/page-video-block/page-video-block';
import PageFooter from './components/page-footer/page-footer';
import PageCookie from './components/page-cookie/page-cookie';
import HowToGuideBlock from './components/how-to/how-to-block';
import StepByStepGuide from './components/how-to-step-by-step-list/how-to-step-by-step-list';
import SafetyFeaturesCards from './components/safety-features/safety-features-cards';
import ActionButtons from './components/action-buttons/action-buttons';
import Button from 'components/button/button';

export default function MainPage() {
    const isBreakpoint = useMediaQuery(900);
    const [open, setOpen] = useState(false);
    const [iframeReload, setIframeReload] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const onModalClose = () => {
        setOpen(false);
    };
    const onCardClickHandler = (event: React.MouseEvent<HTMLLIElement>) => {
        const element = event.currentTarget;
        if (element.dataset.url) {
            // @ts-ignore
            setModalContent((url) => {
                let proxyUrl = element.dataset.url;
                if (
                    window.location.hostname === 'localhost' ||
                    window.location.hostname.indexOf('kube.d3') !== -1
                ) {
                    proxyUrl = element.dataset.url!.replace('https://bumble.com', '/safetycentre');
                }
                setIframeReload(url !== proxyUrl);

                return proxyUrl;
            });
            setOpen(true);
        }
    };

    useEffect(onUseCookieConsentEvent, []);
    useEffect(onUsePrivacyManagerHandler, []);

    useEffect(() => {
        global.window.history.scrollRestoration = 'manual';
        if (
            global.window.location.hash &&
            global.window.location.hash.includes('#scrollPosition=')
        ) {
            // @ts-ignore
            const newScrollPosition = parseInt(
                // @ts-ignore
                global.window.location.hash.split(/\D+/).find((item) => item !== ''),
                10
            );
            global.window.scrollTo(0, newScrollPosition || 0);
        }
    });

    return (
        <>
            <Head>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, viewport-fit=cover"
                />
                <link
                    id="deferred-styles"
                    rel="preload"
                    href="/fonts/Circular20/fonts-circular20.css"
                    as="style"
                />
                <script async src="/viewport-units-buggyfill.js"></script>
            </Head>
            <PageSeo />

            <Page
                header={<PageHeader />}
                content={
                    <>
                        <PageSection theme={PageSection.Theme.NONE} isFullWidth={true}>
                            <PageHeroBlock />
                        </PageSection>
                        <PageSection isSplashScreen={true} theme={PageSection.Theme.NONE}>
                            <Columns isMobileOrderInverted={true}>
                                <ColumnsItem>
                                    <PageVideoBlock />
                                </ColumnsItem>
                                <ColumnsItem>
                                    <CtaBox isFull={true}>
                                        <CtaBoxTitle>
                                            <P1>
                                                <Lexeme path="bumblesite@SAFETY_CENTER.INTRO.PART.ONE" />
                                            </P1>
                                            <br />
                                            <P1>
                                                <Lexeme path="bumblesite@SAFETY_CENTER.INTRO.PART.TWO" />
                                            </P1>
                                        </CtaBoxTitle>

                                        <CtaBoxTitle marginTop={32}>
                                            <StepByStepGuide />
                                        </CtaBoxTitle>
                                        <CtaBoxTitle marginTop={64}>
                                            <Button
                                                isInline={true}
                                                designType={Button.designType.STROKE}
                                                color={Button.color.Primary}
                                                size={Button.size.LARGE}
                                                icon={<Icon size="md" name={Icon.Name.List} />}
                                                // @TODO - add lexeme from https://jira.badoojira.com/browse/LOC-11019
                                                labelText={
                                                    <Lexeme path="bumblesite@safety.landing.safetybook.cta" />
                                                }
                                                text={
                                                    <Lexeme path="bumblesite@safety.landing.safetybook.cta" />
                                                }
                                                href="https://bumble.com/bumble-safety-handbook"
                                                target="blank"
                                            />
                                        </CtaBoxTitle>
                                    </CtaBox>
                                </ColumnsItem>
                            </Columns>
                        </PageSection>
                        <PageSection
                            isSplashScreen={true}
                            theme={PageSection.Theme.NONE}
                            extraClass="page-section--slider"
                        >
                            <HowToGuideBlock onCardClickHandler={onCardClickHandler} />
                        </PageSection>
                        <PageSection
                            isSplashScreen={true}
                            theme={PageSection.Theme.NONE}
                            extraClass="page-section--slider"
                        >
                            <CtaBox isFull={true}>
                                <CtaBoxTitle>
                                    <Header2>
                                        <Lexeme path="bumblesite@SAFETY_CENTER.SAFETY_FEATURES.TITLE" />
                                    </Header2>
                                </CtaBoxTitle>
                                <CtaBoxTitle marginTop={isBreakpoint ? 32 : 40}>
                                    <P1>
                                        <Lexeme path="bumblesite@SAFETY_CENTER.SAFETY_FEATURES.DESCRIPTION" />
                                    </P1>
                                </CtaBoxTitle>
                                <CtaBoxMedia marginTop={isBreakpoint ? 32 : 48}>
                                    <SafetyFeaturesCards />
                                </CtaBoxMedia>
                            </CtaBox>
                        </PageSection>
                        <PageSection isSplashScreen={true} theme={PageSection.Theme.NONE}>
                            <CtaBox isFull={true}>
                                <CtaBoxTitle>
                                    <Header2>
                                        <Lexeme path="bumblesite@SAFETY_CENTER.NEED_HELP.TITLE" />
                                    </Header2>
                                </CtaBoxTitle>
                                <CtaBoxMedia marginTop={isBreakpoint ? 32 : 48}>
                                    <ActionButtons />
                                </CtaBoxMedia>
                            </CtaBox>
                        </PageSection>
                    </>
                }
                footer={<PageFooter />}
                cookieNotification={<PageCookie />}
                modal={
                    <Modal
                        isActive={open}
                        hasIframeReload={iframeReload}
                        onClose={onModalClose}
                        iframeUrl={modalContent}
                    />
                }
            />
            {<IconDefs />}
        </>
    );
}
