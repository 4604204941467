import React from 'react';
import cx from 'classnames';

export enum ColumnsSize {
    '1/1',
    '1/3',
    '2/3',
}

const mapSizeToClasses = {
    [ColumnsSize['1/1']]: 'columns__item',
    [ColumnsSize['1/3']]: 'columns__item--one-third',
    [ColumnsSize['2/3']]: 'columns__item--two-third',
};

type ColumnsPropsType = {
    children?: React.ReactNode;
    size?: ColumnsSize;
};

function ColumnsItem(props: ColumnsPropsType) {
    const { children, size = ColumnsSize['1/3'] } = props;
    const classname = cx({
        columns__item: true,
        [mapSizeToClasses[size]]: true,
    });

    return <div className={classname}>{children}</div>;
}

ColumnsItem.size = ColumnsSize;

export default ColumnsItem;
