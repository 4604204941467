import React from 'react';
import LocaleContext from 'lib/locale-context';
import { Lexeme } from '@bumble/localization/components';
import CtaBox from 'components/cta-box/cta-box';
import CtaBoxTitle from 'components/cta-box/cta-box-title';
import CtaBoxMedia from 'components/cta-box/cta-box-media';
import Image from 'next/legacy/image';
import Header3 from 'components/typography/header-3';
import { howToGuidesCategoriesIds } from './how-to.config';

const CARD_IMAGE_HEIGHT = 205;

const howToGuidesArticleType = {
    article: 'ARTICLE',
    howTo: 'HOW_TO',
};

type HowToGuideCardsPropsType = {
    filter?: React.ReactNode;
    onCardClickHandler?: (event: React.MouseEvent<HTMLLIElement>) => void;
};

const HowToGuideCards: React.FC<HowToGuideCardsPropsType> = (props) => {
    const { filter, onCardClickHandler } = props;
    const lang = React.useContext(LocaleContext);

    const howToGuidesList = [
        {
            type: howToGuidesArticleType.article,
            category: howToGuidesCategoriesIds.consent,
            title: 'With Bumble’s Private Detector, You Have Control Over Unsolicited Nudes',
            imageUrl: '/the-buzz/privatedetector.jpeg',
            get url() {
                return `https://bumble.com/${lang}/the-buzz/privatedetector?app_layout=true`;
            },
            cover: true,
            externalUrl: true,
        },
        {
            type: howToGuidesArticleType.article,
            category: howToGuidesCategoriesIds.consent,
            title: 'How to Ask for Digital Consent',
            imageUrl: '/the-buzz/digital-consent.webp',
            get url() {
                return `https://bumble.com/${lang}/the-buzz/digital-consent`;
            },
            cover: true,
            externalUrl: true,
        },
        {
            type: howToGuidesArticleType.article,
            category: howToGuidesCategoriesIds.consent,
            title: 'How to Set Your Boundaries While Dating',
            imageUrl: '/the-buzz/boundaries.jpeg',
            get url() {
                return `https://bumble.com/${lang}/the-buzz/boundaries-in-dating`;
            },
            cover: true,
            externalUrl: true,
        },
        {
            type: howToGuidesArticleType.article,
            category: howToGuidesCategoriesIds.consent,
            title: 'Five Ways to Make the Safe Sex Conversation with a New Partner Not Awkward',
            imageUrl: '/the-buzz/five-ways.jpeg',
            get url() {
                return `https://bumble.com/${lang}/the-buzz/safe-sex-conversation`;
            },
            cover: true,
            externalUrl: true,
        },
        {
            type: howToGuidesArticleType.article,
            category: howToGuidesCategoriesIds.consent,
            title: 'Common Boundaries You May Want to Set Before a First Date',
            imageUrl: '/the-buzz/first-date-boundaries.jpeg',
            get url() {
                return `https://bumble.com/${lang}/the-buzz/first-date-boundaries`;
            },
            cover: true,
            externalUrl: true,
        },
        {
            type: howToGuidesArticleType.article,
            category: howToGuidesCategoriesIds.consent,
            title: 'eSafety - sexting and sending nudes',
            imageUrl: '/the-buzz/sexting.jpeg',
            //
            get url() {
                return `https://www.esafety.gov.au/key-issues/staying-safe/sending-nudes-sexting`;
            },
            cover: true,
            externalUrl: true,
        },
        {
            type: howToGuidesArticleType.article,
            category: howToGuidesCategoriesIds.mentalExhaustion,
            title: <Lexeme path="profile@safety_center.articles.unsure_where_to_start.title" />,
            imageUrl: '/howtos/where-to-start.png',
            get url() {
                return `https://bumble.com/${lang}/help/43/3329?app_layout=true`;
            },
        },
        {
            type: howToGuidesArticleType.article,
            category: howToGuidesCategoriesIds.mentalExhaustion,
            imageUrl: '/howtos/burnout.png',
            title: <Lexeme path="profile@safety_center.articles.got_dating_burnout.title" />,
            get url() {
                return `https://bumble.com/${lang}/help/43/3331?app_layout=true`;
            },
        },
        // Covid
        // {
        //     type: howToGuidesArticleType.article,
        //     category: howToGuidesCategoriesIds.mentalExhaustion,
        //     imageUrl: '/howtos/covid-anxiety.png',
        //     title: <Lexeme path="profile@safety_center.articles.wellbeing_during_covid.title" />,
        //     get url() {
        //         return `https://bumble.com/${lang}/help/43/3333?app_layout=true`;
        //     },
        // },
        {
            type: howToGuidesArticleType.article,
            category: howToGuidesCategoriesIds.mentalExhaustion,
            imageUrl: '/howtos/first-move.png',
            title: (
                <Lexeme path="profile@safety_center.articles.anxious_about_messaging_first.title" />
            ),
            get url() {
                return `https://bumble.com/${lang}/help/43/3335?app_layout=true`;
            },
        },
        {
            type: howToGuidesArticleType.article,
            category: howToGuidesCategoriesIds.feelingsOfRejection,
            imageUrl: '/howtos/nomatches.png',
            title: <Lexeme path="profile@safety_center.articles.not_getting_any_matches.title" />,
            get url() {
                return `https://bumble.com/${lang}/help/43/3337?app_layout=true`;
            },
        },
        {
            type: howToGuidesArticleType.article,
            category: howToGuidesCategoriesIds.feelingsOfRejection,
            imageUrl: '/howtos/nomessages.png',
            title: (
                <Lexeme path="profile@safety_center.articles.people_dont_message_me_first.title" />
            ),
            get url() {
                return `https://bumble.com/${lang}/help/43/3339?app_layout=true`;
            },
        },
        {
            type: howToGuidesArticleType.article,
            category: howToGuidesCategoriesIds.feelingsOfRejection,
            imageUrl: '/howtos/ghosting.png',
            title: <Lexeme path="profile@safety_center.articles.ghosting.title" />,
            get url() {
                return `https://bumble.com/${lang}/help/43/3341?app_layout=true`;
            },
        },
        {
            type: howToGuidesArticleType.article,
            category: howToGuidesCategoriesIds.feelingsOfRejection,
            imageUrl: '/howtos/beingunmatched.png',
            title: <Lexeme path="profile@safety_center.articles.being_unmatched.title" />,
            get url() {
                return `https://bumble.com/${lang}/help/43/3343?app_layout=true`;
            },
        },
        {
            type: howToGuidesArticleType.article,
            category: howToGuidesCategoriesIds.harmfulBehaviour,
            imageUrl: '/howtos/romance-scams.jpg',
            title: <Lexeme path="profile@safety_center_guides_harmful_behaviour_article_6_title" />,
            get url() {
                return `https://bumble.com/${lang}/help/romance-scams?app_layout=true`;
            },
        },
        {
            type: howToGuidesArticleType.article,
            category: howToGuidesCategoriesIds.harmfulBehaviour,
            imageUrl: '/howtos/reportingmessage.png',
            title: (
                <Lexeme path="profile@safety_center.articles.reporting_abusive_messages.title" />
            ),
            get url() {
                return `https://bumble.com/${lang}/help/43/3345?app_layout=true`;
            },
        },
        {
            type: howToGuidesArticleType.article,
            category: howToGuidesCategoriesIds.harmfulBehaviour,
            imageUrl: '/howtos/internalreporting.png',
            title: (
                <Lexeme path="profile@safety_center.articles.what_happens_when_i_report.title" />
            ),
            get url() {
                return `https://bumble.com/${lang}/help/43/3347?app_layout=true`;
            },
        },
        {
            type: howToGuidesArticleType.article,
            category: howToGuidesCategoriesIds.harmfulBehaviour,
            imageUrl: '/howtos/catfishing.png',
            title: (
                <Lexeme path="profile@safety_center.articles.spotting_recovering_catfish.title" />
            ),
            get url() {
                return `https://bumble.com/${lang}/help/43/3349?app_layout=true`;
            },
        },
        {
            type: howToGuidesArticleType.article,
            category: howToGuidesCategoriesIds.harmfulBehaviour,
            imageUrl: '/howtos/microaggressions.png',
            title: (
                <Lexeme path="profile@safety_center.articles.microaggressions_fetishization.title" />
            ),
            get url() {
                return `https://bumble.com/${lang}/help/43/3351?app_layout=true`;
            },
        },
        {
            type: howToGuidesArticleType.article,
            category: howToGuidesCategoriesIds.bumbleToIrl,
            imageUrl: '/howtos/safety-irl.png',
            title: <Lexeme path="profile@safety_center.articles.preparing_for_safety_irl.title" />,
            get url() {
                return `https://bumble.com/${lang}/help/43/3353?app_layout=true`;
            },
        },
        {
            type: howToGuidesArticleType.article,
            category: howToGuidesCategoriesIds.bumbleToIrl,
            imageUrl: '/howtos/first-date.png',
            title: (
                <Lexeme path="profile@safety_center.articles.how_to_navigate_first_dates.title" />
            ),
            get url() {
                return `https://bumble.com/${lang}/help/43/3355?app_layout=true`;
            },
        },
        // Covid
        // {
        //     type: howToGuidesArticleType.article,
        //     category: howToGuidesCategoriesIds.bumbleToIrl,
        //     imageUrl: '/howtos/covid-dating.png',
        //     title: <Lexeme path="profile@safety_center.articles.irl_dating_during_covid.title" />,
        //     get url() {
        //         return `https://bumble.com/${lang}/help/43/3357?app_layout=true`;
        //     },
        // },
        {
            type: howToGuidesArticleType.article,
            category: howToGuidesCategoriesIds.romanceScams,
            title: 'Online dating',
            imageUrl: '/the-buzz/online-dating.jpeg',
            get url() {
                return `https://www.esafety.gov.au/women/being-social/online-dating`;
            },
            cover: true,
            externalUrl: true,
        },
        {
            type: howToGuidesArticleType.article,
            category: howToGuidesCategoriesIds.romanceScams,
            title: 'Online scams',
            imageUrl: '/the-buzz/online-scams.jpeg',
            get url() {
                return `https://www.esafety.gov.au/key-issues/staying-safe/online-scams`;
            },
            cover: true,
            externalUrl: true,
        },
    ];

    return (
        <ul className="cards cards--howto">
            {howToGuidesList.map(
                ({ category, imageUrl, title, url, cover, externalUrl }, index) => {
                    const contentCard = externalUrl ? (
                        <a
                            className="cards__item"
                            key={`${url}-${index}`}
                            href={url}
                            target="blank"
                        >
                            <CtaBox isFull={true}>
                                {imageUrl ? (
                                    <CtaBoxMedia>
                                        <Image
                                            className="cta-box__media-img"
                                            src={imageUrl || '/icons/icon-logo.png'}
                                            width={280}
                                            height={CARD_IMAGE_HEIGHT}
                                            objectFit={cover ? 'cover' : 'contain'}
                                            alt=""
                                        />
                                    </CtaBoxMedia>
                                ) : (
                                    <div style={{ height: CARD_IMAGE_HEIGHT }} />
                                )}
                                {title ? (
                                    <CtaBoxTitle marginTop={45}>
                                        <span className="cards__title">
                                            <Header3>{title}</Header3>
                                        </span>
                                    </CtaBoxTitle>
                                ) : (
                                    ''
                                )}
                            </CtaBox>
                        </a>
                    ) : (
                        <li
                            className="cards__item"
                            key={`${url}-${index}`}
                            data-url={url}
                            onClick={onCardClickHandler}
                        >
                            <CtaBox isFull={true}>
                                {imageUrl ? (
                                    <CtaBoxMedia>
                                        <Image
                                            className="cta-box__media-img"
                                            src={imageUrl || '/icons/icon-logo.png'}
                                            width={280}
                                            height={CARD_IMAGE_HEIGHT}
                                            objectFit={cover ? 'cover' : 'contain'}
                                            alt=""
                                        />
                                    </CtaBoxMedia>
                                ) : (
                                    <div style={{ height: CARD_IMAGE_HEIGHT }} />
                                )}
                                {title ? (
                                    <CtaBoxTitle marginTop={45}>
                                        <span className="cards__title">
                                            <Header3>{title}</Header3>
                                        </span>
                                    </CtaBoxTitle>
                                ) : (
                                    ''
                                )}
                            </CtaBox>
                        </li>
                    );
                    return category === filter ? contentCard : undefined;
                }
            )}
        </ul>
    );
};

export default HowToGuideCards;
