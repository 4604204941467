import React from 'react';
import { Lexeme } from '@bumble/localization/components';
import Image from 'next/legacy/image';

const ICON_SQUARE_LG_SIZE = 48;

const needHelpActions = [
    {
        key: 'bumblesite@SAFETY_CENTER.NEED_HELP.CALL.TITLE',
        type: 'text',
        icon: (
            <Image
                className="cta-box__media-img"
                src="/icons/icon-need-help.png"
                width={ICON_SQUARE_LG_SIZE}
                height={ICON_SQUARE_LG_SIZE}
                objectFit="contain"
                alt=""
            />
        ),
        title: <Lexeme path="bumblesite@SAFETY_CENTER.NEED_HELP.CALL.TITLE" />,
    },
    {
        key: 'bumblesite@SAFETY_CENTER.NEED_HELP.HELP_CENTER.TITLE',
        type: 'link',
        icon: (
            <Image
                className="cta-box__media-img"
                src="/icons/icon-question.png"
                width={ICON_SQUARE_LG_SIZE}
                height={ICON_SQUARE_LG_SIZE}
                objectFit="contain"
                alt=""
            />
        ),
        title: <Lexeme path="bumblesite@SAFETY_CENTER.NEED_HELP.HELP_CENTER.TITLE" />,
        text: `See our list of <a href="https://bumble.com/en/help/helpful-resources-for-support">Helpful&nbsp;Resources&nbsp;for&nbsp;Support</a> (country-specific). <br /><br /> For more information on Privacy and Safety, Bumble 101, Troubleshooting, and more, visit our <a href="https://bumble.com/help?from=safety-center">Help Centre</a>.`,
        // text: (
        //     <Lexeme
        //         path="bumblesite@SAFETY_CENTER.NEED_HELP.HELP_CENTER.DESCRIPTION"
        //         params={{ helpCenterUrl: 'https://bumble.com/help?from=safety-center' }}
        //     />
        // ),
        onClick: true,
    },
];

const ActionButtons = () => (
    <ul className="action-buttons">
        {needHelpActions.map(({ title, icon, text, key, onClick }) => (
            <li
                className={onClick ? 'action-buttons__item is-active' : 'action-buttons__item'}
                key={key}
            >
                {icon ? <span className="action-buttons__icon">{icon}</span> : null}
                {title || text ? (
                    <div className="action-buttons__text">
                        {title ? <span className="action-buttons__title">{title}</span> : null}
                        {text ? (
                            <span
                                className="action-buttons__description"
                                dangerouslySetInnerHTML={{
                                    __html: text,
                                }}
                            />
                        ) : null}
                    </div>
                ) : null}
            </li>
        ))}
    </ul>
);

export default ActionButtons;
