import { Lexeme } from '@bumble/localization/components';
import Footer from 'components/footer/footer';
import FooterCaption from 'components/footer/footer-caption';
import FooterMenu from 'components/footer/footer-menu';
import Icon from 'components/icon/icon';
import LanguageSelector from 'components/language-selector/language-selector';
import SocialLinks from 'components/social-links/social-links';
import GeoContext from 'lib/geo-context';
import LocaleContext from 'lib/locale-context';
import Localization from 'lib/localization';
import useMediaQuery from 'lib/use-media-query';
import React from 'react';

enum Theme {
    DATE = 'DATING',
    BIZZ = 'BIZZ',
    BFF = 'BFF',
    NONE = 'NONE',
}

const postsCategoriesForMainPage = {
    Love: 'Love',
    Friendship: 'Friendship',
    Career: 'Career',
} as const;

const postsCategories = {
    ...postsCategoriesForMainPage,
    HowTo: 'How To',
    Wellness: 'Wellness',
    Events: 'Events',
} as const;

const bumbleModesMenu = [
    {
        get title() {
            return Localization.get('bumblesite@LAYOUT.FOOTER.BUMBLE_DATE');
        },
        getUrl() {
            return 'https://bumble.com/date';
        },
    },
    {
        get title() {
            return Localization.get('bumblesite@LAYOUT.FOOTER.BUMBLE_BFF');
        },
        getUrl() {
            return 'https://bumble.com/bff';
        },
    },
    {
        get title() {
            return Localization.get('bumblesite@LAYOUT.FOOTER.BUMBLE_BIZZ');
        },
        getUrl() {
            return 'https://bumble.com/bizz';
        },
    },
];

const pageCategories = {
    LOVE: {
        priority: 1,
        id: 'love',
        get title() {
            return Localization.get('bumblesite@LAYOUT.FOOTER.BEEHIVE.LOVE');
        },
        get subTitle() {
            // @todo add lexeme
            return 'Modern Romance';
        },
        url: '/the-buzz/love',
        theme: Theme.DATE,
        category: postsCategories.Love,
    },
    FRIENDSHIP: {
        priority: 2,
        id: 'friendship',
        get title() {
            return Localization.get('bumblesite@LAYOUT.FOOTER.BEEHIVE.FRIENDSHIP');
        },
        get subTitle() {
            // @todo add lexeme
            return 'Bumble for Besties';
        },
        url: '/the-buzz/friendship',
        theme: Theme.BFF,
        category: postsCategories.Friendship,
    },
    CAREER: {
        priority: 3,
        id: 'career',
        get title() {
            return Localization.get('bumblesite@LAYOUT.FOOTER.BEEHIVE.CAREER');
        },
        get subTitle() {
            // @todo add lexeme
            return 'A Career that Counts';
        },
        url: '/the-buzz/career',
        theme: Theme.BIZZ,
        category: postsCategories.Career,
    },
    WELLNESS: {
        priority: 4,
        id: 'wellness',
        get title() {
            return Localization.get('bumblesite@LAYOUT.FOOTER.BEEHIVE.WELLNESS');
        },
        get subTitle() {
            // @todo add lexeme
            return null;
        },
        url: '/the-buzz/wellness',
        theme: Theme.DATE,
        category: postsCategories.Wellness,
    },
    HOWTO: {
        priority: 5,
        id: 'howto',
        get title() {
            return Localization.get('bumblesite@LAYOUT.FOOTER.BEEHIVE.HOW_TO');
        },
        get subTitle() {
            // @todo add lexeme
            return null;
        },
        url: '/the-buzz/how-to',
        theme: Theme.DATE,
        category: postsCategories.HowTo,
    },
    EVENTS: {
        priority: 6,
        id: 'events',
        get title() {
            return Localization.get('bumblesite@LAYOUT.FOOTER.EVENTS');
        },
        get subTitle() {
            // @todo add lexeme
            return null;
        },
        url: '/the-buzz/events',
        theme: Theme.DATE,
        category: postsCategories.Events,
    },
} as const;

export const getFooterMenuItems = (geoIpCountry?: string | number | null) => {
    const privacyManager: string = geoIpCountry !== 'us' ? 'gdpr' : 'ccpa';

    return [
        {
            get title() {
                return Localization.get('bumblesite@LAYOUT.FOOTER.THEAPP');
            },
            url: 'https://bumble.com/date',
            list: bumbleModesMenu,
        },
        {
            get title() {
                return Localization.get('bumblesite@LAYOUT.FOOTER.COMPANY');
            },
            url: 'https://bumble.com/about',
            list: [
                {
                    get title() {
                        return Localization.get('bumblesite@LAYOUT.FOOTER.HELP_CENTER');
                    },
                    getUrl(lang: string) {
                        return `https://bumble.com/${lang}/help`;
                    },
                },
                {
                    get title() {
                        return Localization.get('bumblesite@LAYOUT.FOOTER.EVENTS');
                    },
                    getUrl() {
                        return 'https://bumble.events/eventscalendar';
                    },
                },
                {
                    get title() {
                        return Localization.get('bumblesite@LAYOUT.FOOTER.GUIDELINES');
                    },
                    getUrl(lang: string) {
                        return `https://bumble.com/${lang}/guidelines`;
                    },
                },
                {
                    get title() {
                        return Localization.get('bumblesite@LAYOUT.FOOTER.PRIVACY_POLICY');
                    },
                    getUrl(lang: string) {
                        return `https://bumble.com/${lang}/privacy`;
                    },
                },
                {
                    get title() {
                        return privacyManager === 'gdpr'
                            ? Localization.get('bumblesite@LAYOUT.FOOTER.MANAGE_COOKIES')
                            : Localization.get('bumblesite@ccpa_cta.title');
                    },
                    getUrl(lang: string) {
                        return `https://bumble.com/${lang}/cookie-policy`;
                    },
                    optionalClass:
                        privacyManager === 'gdpr' ? 'js-manage-cookies' : 'js-ccpa-privacy',
                },
                {
                    get title() {
                        return Localization.get('bumblesite@LAYOUT.FOOTER.TNC');
                    },
                    getUrl(lang: string) {
                        return `https://bumble.com/${lang}/terms`;
                    },
                },
                {
                    get title() {
                        return Localization.get('bumblesite@LAYOUT.FOOTER.CAREERS');
                    },
                    getUrl() {
                        return 'https://thebeehive.bumble.com/careers/';
                    },
                },
            ],
        },
        {
            get title() {
                return Localization.get('bumblesite@LAYOUT.HEADER.BEEHIVE');
            },
            getUrl(lang: string) {
                return `https://bumble.com/${lang}/the-buzz`;
            },
            list: [
                {
                    get title() {
                        return Localization.get('bumblesite@LAYOUT.FOOTER.BEEHIVE.LOVE');
                    },
                    getUrl(lang: string) {
                        return `https://bumble.com/${lang}${pageCategories.LOVE.url}`;
                    },
                },
                {
                    get title() {
                        return Localization.get('bumblesite@LAYOUT.FOOTER.BEEHIVE.FRIENDSHIP');
                    },
                    getUrl(lang: string) {
                        return `https://bumble.com/${lang}${pageCategories.FRIENDSHIP.url}`;
                    },
                },
                {
                    get title() {
                        return Localization.get('bumblesite@LAYOUT.FOOTER.BEEHIVE.CAREER');
                    },
                    getUrl(lang: string) {
                        return `https://bumble.com/${lang}${pageCategories.CAREER.url}`;
                    },
                },
                {
                    get title() {
                        return Localization.get('bumblesite@LAYOUT.FOOTER.BEEHIVE.WELLNESS');
                    },
                    getUrl(lang: string) {
                        return `https://bumble.com/${lang}${pageCategories.WELLNESS.url}`;
                    },
                },
                {
                    get title() {
                        return Localization.get('bumblesite@LAYOUT.FOOTER.BEEHIVE.HOW_TO');
                    },
                    getUrl(lang: string) {
                        return `https://bumble.com/${lang}${pageCategories.HOWTO.url}`;
                    },
                },
                {
                    get title() {
                        return Localization.get('bumblesite@LAYOUT.FOOTER.EVENTS');
                    },
                    getUrl(lang: string) {
                        return `https://bumble.com/${lang}${pageCategories.EVENTS.url}`;
                    },
                },
                {
                    get title() {
                        return Localization.get('bumblesite@LAYOUT.FOOTER.BEEHIVE.SUBMIT_STORY');
                    },
                    getUrl() {
                        return 'https://bumble.com/success';
                    },
                },
            ],
        },
    ];
};

// @ts-ignore
const PageFooter = () => {
    const lang = React.useContext(LocaleContext);
    const country = React.useContext(GeoContext);
    const isBreakpoint = useMediaQuery(900);

    //@todo - https://jira.badoojira.com/browse/LOC-5433
    const socialFooterMenu = {
        title: Localization.get('bumblesite@LAYOUT.FOOTER.FOLLOW.TITLE'),
        content: Localization.get('bumblesite@LAYOUT.FOOTER.FOLLOW.CAPTION'),
        socialLinks: (
            <SocialLinks
                items={[
                    {
                        url: 'https://www.facebook.com/bumbleapp/',
                        icon: <Icon size="md" name={Icon.Name.FacebookSquare} />,
                        labelText: 'Our Facebook page',
                    },
                    {
                        url: 'https://www.instagram.com/bumble/',
                        icon: <Icon size="md" name={Icon.Name.Instagram} />,
                        labelText: 'Our Instagram account',
                    },
                    {
                        url: 'https://twitter.com/bumble',
                        icon: <Icon size="md" name={Icon.Name.Twitter} />,
                        labelText: 'Our Twitter account',
                    },
                    {
                        url: 'https://www.pinterest.com/bumbleapp/',
                        icon: <Icon size="md" name={Icon.Name.Pinterest} />,
                        labelText: 'Our Pinterest account',
                    },
                ]}
            />
        ),
    };

    const footerMenuItems = getFooterMenuItems(country);

    return (
        <Footer
            copyright={
                <Lexeme
                    path="corp@copyright_notice"
                    params={{ current_year: new Date().getFullYear() }}
                />
            }
        >
            <>
                <FooterMenu {...footerMenuItems[0]} />
                {!isBreakpoint ? (
                    <FooterCaption>
                        <LanguageSelector currentLanguageCode={lang} />
                    </FooterCaption>
                ) : null}
            </>
            <FooterMenu {...footerMenuItems[1]} />
            <FooterMenu {...footerMenuItems[2]} />
            <FooterMenu {...socialFooterMenu} />
            {isBreakpoint ? (
                <FooterCaption>
                    <LanguageSelector currentLanguageCode={lang} />
                </FooterCaption>
            ) : null}
        </Footer>
    );
};

export default PageFooter;
