import React from 'react';
import { Lexeme } from '@bumble/localization/components';
import CtaBox from 'components/cta-box/cta-box';
import CtaBoxTitle from 'components/cta-box/cta-box-title';
import CtaBoxMedia from 'components/cta-box/cta-box-media';
import Header2 from 'components/typography/header-2';
import HowToGuideCards from './how-to-cards';
import HowToGuideButtons from './how-to-buttons';
import useMediaQuery from 'lib/use-media-query';
import { howToGuidesCategoriesIds } from './how-to.config';

type HowToGuideBlockPropsType = {
    onCardClickHandler?: (event: React.MouseEvent<HTMLLIElement>) => void;
};

const INITIAL_CATEGORY = howToGuidesCategoriesIds.consent;

const HowToGuideBlock: React.FC<HowToGuideBlockPropsType> = (props) => {
    const { onCardClickHandler } = props;
    const isBreakpoint = useMediaQuery(900);
    const [filter, setFilter] = React.useState(INITIAL_CATEGORY);

    const onButtonClickHandler = (event: any) => setFilter(event);

    return (
        <CtaBox isFull={true}>
            <CtaBoxTitle>
                <Header2>
                    <Lexeme path="bumblesite@SAFETY_CENTER.HOW_TO.TITLE" />
                </Header2>
            </CtaBoxTitle>
            <CtaBoxMedia marginTop={isBreakpoint ? 32 : 40}>
                <HowToGuideButtons filter={filter} onClickHandler={onButtonClickHandler} />
            </CtaBoxMedia>
            <CtaBoxMedia marginTop={isBreakpoint ? 32 : 48}>
                <HowToGuideCards filter={filter} onCardClickHandler={onCardClickHandler} />
            </CtaBoxMedia>
        </CtaBox>
    );
};

export default HowToGuideBlock;
