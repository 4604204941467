import React from 'react';
import cx from 'classnames';

export enum CtaBoxMediaAlign {
    CENTERED = 'CENTERED',
}

const mapAlignToClasses = {
    [CtaBoxMediaAlign.CENTERED]: 'cta-box__media--centered',
};

type CtaBoxMediaProps = {
    align?: CtaBoxMediaAlign;
    hasNoMargin?: boolean;
    marginTop?: number;
    children?: React.ReactChild | React.ReactChild[];
};

const CtaBoxMedia: React.FC<CtaBoxMediaProps> = (props) => {
    const { align, children, hasNoMargin, marginTop } = props;
    const classname = cx(
        {
            'cta-box__media': true,
            'cta-box__media--no-margin': hasNoMargin,
        },
        typeof align !== 'undefined' && mapAlignToClasses[align]
    );
    const style = {
        marginTop,
    };

    return (
        <div className={classname} style={style}>
            {children}
        </div>
    );
};

export default CtaBoxMedia;
