import React from 'react';
import { Lexeme } from '@bumble/localization/components';
import Image from 'next/legacy/image';
import P2 from 'components/typography/p-2';

const ICON_SQUARE_SIZE = 40;

const howToStepByStep = [
    {
        key: 'bumblesite@SAFETY_CENTER.INTRO.TITLE',
        title: <Lexeme path="bumblesite@SAFETY_CENTER.INTRO.TITLE" />,
    },
    {
        key: 'bumblesite@SAFETY_CENTER.INTRO.STEP.ONE',
        icon: (
            <Image
                className="cta-box__media-img"
                src="/icons/icon-logo.png"
                width={ICON_SQUARE_SIZE}
                height={ICON_SQUARE_SIZE}
                objectFit="contain"
                alt=""
            />
        ),
        text: <Lexeme path="bumblesite@SAFETY_CENTER.INTRO.STEP.ONE" />,
    },
    {
        key: 'bumblesite@SAFETY_CENTER.INTRO.STEP.TWO',
        icon: (
            <Image
                className="cta-box__media-img"
                src="/icons/icon-profile.png"
                width={ICON_SQUARE_SIZE}
                height={ICON_SQUARE_SIZE}
                objectFit="contain"
                alt=""
            />
        ),
        text: <Lexeme path="bumblesite@SAFETY_CENTER.INTRO.STEP.TWO" />,
    },
    {
        key: 'bumblesite@SAFETY_CENTER.INTRO.STEP.THREE',
        icon: (
            <Image
                className="cta-box__media-img"
                src="/icons/icon-tick.png"
                width={ICON_SQUARE_SIZE}
                height={ICON_SQUARE_SIZE}
                objectFit="contain"
                alt=""
            />
        ),
        text: <Lexeme path="bumblesite@SAFETY_CENTER.INTRO.STEP.THREE" />,
    },
];

const StepByStepGuide = () => (
    <ul className="list-icon-text">
        {howToStepByStep.map(({ title, icon, text, key }) => (
            <li className="list-icon-text__item" key={key}>
                {title ? (
                    <span className="list-icon-text__title">
                        <P2>
                            <b>{title}</b>
                        </P2>
                    </span>
                ) : null}
                {icon ? <span className="list-icon-text__icon">{icon}</span> : null}
                {text ? (
                    <span className="list-icon-text__text">
                        <P2>{text}</P2>
                    </span>
                ) : null}
            </li>
        ))}
    </ul>
);

export default StepByStepGuide;
