import React from 'react';
import cx from 'classnames';
import Icon from 'components/icon/icon';
import Loader, { LoaderSize } from 'components/loader/loader';
import virtualUnitsPolyFill from 'lib/virtual-units-polyfill';

type Props = {
    jsBlockClass?: string;
    jsFadeClass?: string;
    jsCloseClass?: string;
    jsContentClass?: string;
    icon?: React.ReactNode;
    isActive?: boolean;
    iframeUrl?: string;
    hasIframeReload?: boolean;
    onClose?: () => void;
    children?: React.ReactNode;
};

function Modal(props: Props) {
    const {
        jsBlockClass = 'js-modal',
        jsFadeClass = 'js-modal-close',
        jsCloseClass = 'js-modal-close',
        jsContentClass = 'js-modal-content',
        icon = <Icon size="md" name={Icon.Name.Close} />,
        onClose,
        isActive,
        iframeUrl,
        hasIframeReload,
        children,
    } = props;

    const [isLoading, setIsLoading] = React.useState(true);
    const inframeEl = React.useRef<HTMLIFrameElement>(null);

    const className = {
        block: cx({
            modal: true,
            'is-active': isActive,
            [jsBlockClass]: jsBlockClass,
        }),
        fade: cx({
            modal__fade: true,
            [jsFadeClass]: jsFadeClass,
        }),
        closeAction: cx({
            modal__close: true,
            [jsCloseClass]: jsCloseClass,
        }),
        content: cx({
            modal__content: true,
            [jsContentClass]: jsContentClass,
            'is-hidden': isLoading && hasIframeReload,
        }),
    };

    const onCloseClickHandler = () => {
        setIsLoading(true);

        if (onClose) {
            onClose();
        }
    };

    const activateIframeForMobile = () => {
        if (inframeEl.current) {
            const iframe = inframeEl.current;

            // @ts-ignore
            iframe.style.zoom = '100.5%';

            setTimeout(() => {
                // @ts-ignore
                iframe.style.zoom = '100.25%';
                // @ts-ignore
                if (global.window.viewportUnitsBuggyfill) {
                    // @ts-ignore
                    global.window.viewportUnitsBuggyfill.refresh();
                }
            }, 100);

            setTimeout(() => {
                // @ts-ignore
                iframe.style.zoom = '100%';
                // @ts-ignore
                if (global.window.viewportUnitsBuggyfill) {
                    // @ts-ignore
                    global.window.viewportUnitsBuggyfill.refresh();
                }
            }, 300);
        }
    };

    React.useEffect(() => {
        const isMobile =
            global.document.documentElement.classList.contains('android') ||
            global.document.documentElement.classList.contains('ios');
        virtualUnitsPolyFill();
        global.document.documentElement.style.overflow = isActive ? 'hidden' : '';
        global.document.documentElement.style.maxHeight = isActive ? '100vh' : '';

        if (isActive) {
            if (iframeUrl && isMobile) {
                global.window.location.hash = `#scrollPosition=${global.window.scrollY}`;
                global.window.location.href = `${iframeUrl}&safety_center=true`;
            }
            if (isMobile) {
                global.document.documentElement.classList.add('has-no-scroll');
                activateIframeForMobile();
            }
        } else {
            if (isMobile) {
                global.document.documentElement.classList.remove('has-no-scroll');
            }
        }
    }, [isActive, iframeUrl]);

    return (
        <div className={className.block}>
            <div className={className.fade} onClick={onCloseClickHandler}></div>
            <div className="modal__inner">
                <div className="modal__control">
                    <div className={className.closeAction} onClick={onCloseClickHandler}>
                        {icon}
                    </div>
                </div>
                <div className="modal__frame">
                    {iframeUrl ? (
                        <>
                            {isLoading && hasIframeReload ? (
                                <Loader size={LoaderSize.LARGE} />
                            ) : null}
                            <iframe
                                className={className.content}
                                src={iframeUrl}
                                onLoad={() => {
                                    setIsLoading(Boolean(!hasIframeReload));
                                    activateIframeForMobile();
                                }}
                                scrolling="yes"
                                ref={inframeEl}
                            />
                        </>
                    ) : (
                        <div className={className.content}>{children}</div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Modal;
