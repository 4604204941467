import React, { useContext } from 'react';
import Link from 'components/link/link';
import LocaleContext from 'lib/locale-context';

type Props = {
    url?: string;
    title?: string;
    getUrl?: (lang: string) => string;
    list?: {
        title?: string;
        getUrl: (lang: string) => string;
        optionalClass?: string;
    }[];
    content?: React.ReactNode;
    socialLinks?: React.ReactNode;
};

function FooterMenu(props: Props) {
    const { url, getUrl, title, list, content, socialLinks } = props;

    const lang = useContext(LocaleContext);

    const titleUrl = url || (getUrl && getUrl(lang));

    return (
        <div className="footer-menu">
            <h3 className="footer-menu__title">
                {titleUrl ? (
                    <Link className="footer-menu__title-link" href={titleUrl} title={title}>
                        {title}
                    </Link>
                ) : (
                    title
                )}
            </h3>

            {list ? (
                <ul className="footer-menu__list">
                    {list.map(({ getUrl: listGetUrl, title: listTitle, optionalClass }, index) => (
                        <li className="footer-menu__item" key={`footer-menu-item-${index}`}>
                            <Link
                                className={`footer-menu__link${
                                    optionalClass ? ` ${optionalClass}` : ''
                                }`}
                                href={listGetUrl(lang)}
                                title={listTitle}
                            >
                                {listTitle}
                            </Link>
                        </li>
                    ))}
                </ul>
            ) : null}

            {content ? <div className="footer-menu__content">{content}</div> : null}

            {socialLinks ? <div className="footer-menu__social-links">{socialLinks}</div> : null}
        </div>
    );
}

export default FooterMenu;
