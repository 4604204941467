import React from 'react';
import cx from 'classnames';

type ButtonGroupPropsType = {
    children?: React.ReactNode | React.ReactNode[];
    key?: string;
    size?: 'md' | 'sm' | 'lg';
    extraClass?: string;
};

function ButtonGroup(props: ButtonGroupPropsType) {
    const className = cx({
        'button-group': true,
        [`button-group--${props.size}`]: props.size,
        [props.extraClass as string]: props.extraClass,
    });
    return (
        <div className={className}>
            {React.Children.toArray(props.children)
                .filter(Boolean)
                .map((item, index) => (
                    <div className="button-group__item" key={`button-group-${props.key}-${index}`}>
                        {item}
                    </div>
                ))}
        </div>
    );
}

export default ButtonGroup;
