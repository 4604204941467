import Localization from 'lib/localization';

export default {
    lxCookieBannerText(params: { cookiePolicyLink: string }) {
        // copy from MW-11391
        let text = Localization.get('settings@cookies.banner.body');
        text = text.replace(
            /<a p\d>/,
            `<a style="text-decoration: underline; color: currentColor;" target="_blank" href="${params.cookiePolicyLink}">`
        );
        return text;
    },
};
