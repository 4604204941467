import React from 'react';
import cx from 'classnames';

type HeroBlockProps = {
    children: React.ReactNode;
    isFullScreen?: boolean;
    color?: 'primary' | 'white';
};

function HeroBlock(props: HeroBlockProps) {
    const className = cx({
        'hero-block': true,
        'hero-block--fullscreen': props.isFullScreen,
        [`hero-block--${props.color}`]: props.color,
    });
    const HeroBlockElement = <div className={className}>{props.children}</div>;

    return HeroBlockElement;
}

export default HeroBlock;
